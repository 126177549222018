/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const router = {
  path: '/guanghe',
  component: Layout,
  children: [{
    path: '',
    name: 'guanghe',
    meta: {
      title: "晔光小站"
    },
    component: () =>
      import('@/views/guanghe/index.vue')
  }, {
    path: 'detail/:Id',
    name: 'detail',
    meta: {
      title: "服务详情"
    },
    component: () =>
      import('@/views/guanghe/detail.vue')
  }, {
    path: '/guanghe/bianmin',
    name: 'bianmin',
    meta: {
      title: "便民事宜"
    },
    component: () =>
      import('@/views/guanghe/bianmin.vue')
  }, {
    path: '/guanghe/newAdd',
    name: 'newAdd',
    meta: {
      title: "居民登记"
    },
    component: () =>
      import('@/views/guanghe/newAdd.vue')
  }, {
    path: '/guanghe/topics',
    name: 'topics',
    meta: {
      title: "政协议事"
    },
    component: () =>
      import('@/views/guanghe/topics.vue')
  }, {
    path: '/guanghe/youth',
    name: 'youth',
    meta: {
      title: "青年活力调查表"
    },
    component: () =>
      import('@/views/guanghe/youth.vue')
  },{
    path: '/guanghe/brief',
    name: 'brief',
    meta: {
      title: "晔光小站简介"
    },
    component: () =>
      import('@/views/guanghe/brief.vue')
  }, {
    path: '/guanghe/evaluating',
    name: 'evaluating',
    meta: {
      title: "建言献策"
    },
    component: () =>
      import('@/views/guanghe/evaluating.vue')
  }, {
    path: '/guanghe/zhiyuan',
    name: 'zhiyuan',
    meta: {
      title: "志愿活动"
    },
    component: () =>
      import('@/views/guanghe/zhiyuan.vue')
  }, {
    path: '/guanghe/demand',
    name: 'demand',
    meta: {
      title: "需求清单"
    },
    component: () =>
      import('@/views/guanghe/demand.vue')
  }, {
    path: '/guanghe/resource',
    name: 'resource',
    meta: {
      title: "资源清单"
    },
    component: () =>
      import('@/views/guanghe/resource.vue')
  }, {
    path: '/guanghe/project',
    name: 'project',
    meta: {
      title: "项目清单"
    },
    component: () =>
      import('@/views/guanghe/project.vue')
  }, {
    path: '/guanghe/wantfrom',
    name: 'wantfrom',
    meta: {
      title: "微心愿"
    },
    component: () =>
      import('@/views/guanghe/wantfrom.vue')
  }, {
    path: '/guanghe/register',
    name: 'register',
    meta: {
      title: "居民登记"
    },
    component: () =>
      import('@/views/guanghe/register.vue')
  }, {
    path: '/guanghe/integral',
    name: 'integral',
    meta: {
      title: "积分记录"
    },
    component: () =>
      import('@/views/guanghe/integral.vue')
  },]
}

export default router