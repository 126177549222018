import Vue from 'vue'
import AMap from 'vue-amap';
import App from './App.vue'
import router from './router'
import store from './store'
import '@/style/common.css' // global css
// import Cookies from 'js-cookie'

// vant 组件引入
import Vant from 'vant';
import 'vant/lib/index.css';
import VueCanvasPoster from 'vue-canvas-poster';

Vue.use(Vant);
Vue.use(AMap);
Vue.config.productionTip = false
Vue.use(VueCanvasPoster)
    // 初始化vue-amap
AMap.initAMapApiLoader({
    // 高德key
    key: '8859b8e7a76bd03f160f3a208cfe9b1e',
    // 插件集合 （插件按需引入）
    plugin: ['AMap.Geolocation']
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')