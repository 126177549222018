import Layout from '@/layout'

const router = {
    path: '/partymember',
    component: Layout,
    children: [{
            path: '',
            meta: {
                title: "智慧党建"
            },
            component: () =>
                import ('@/views/partymember/index.vue')
        },
        {
            path: 'message',
            meta: {
                title: "通知公告"
            },
            component: () =>
                import ('@/views/partymember/message/index.vue')
        },
        {
            path: 'consult',
            meta: {
                title: "党建资讯"
            },
            component: () =>
                import ('@/views/partymember/consult/index.vue')
        },
        {
            path: 'lookback',
            meta: {
                title: "党史学习"
            },
            component: () =>
                import ('@/views/partymember/lookback/index.vue')
        },
        {
            path: 'integral',
            meta: {
                title: "有困难找党员"
            },
            component: () =>
                import ('@/views/partymember/integral/index.vue')
        },
        {
            path: 'history',
            meta: {
                title: "党史教育"
            },
            component: () =>
                import ('@/views/partymember/history/index.vue')
        },
        {
            path: 'activity',
            meta: {
                title: "党务活动"
            },
            component: () =>
                import ('@/views/partymember/activity/index.vue')
        },
        {
            path: 'activity/:Id',
            meta: {
                title: "活动详情"
            },
            component: () =>
                import ('@/views/partymember/activity/detail.vue')
        },
        {
            path: 'history/activity/:Id',
            meta: {
                title: "活动详情"
            },
            component: () =>
                import ('@/views/partymember/activity/detail.vue')
        },
        {
            path: 'message/:Id',
            meta: {
                title: "通知公告"
            },
            component: () =>
                import ('@/views/partymember/information/information.vue')
        },
        {
            path: 'consult/:Id',
            meta: {
                title: "资讯详情"
            },
            component: () =>
                import ('@/views/partymember/information/information.vue')
        },
        {
            path: 'history/:Id',
            meta: {
                title: "我为群众办实事"
            },
            component: () =>
                import ('@/views/partymember/information/information.vue')
        }
    ]
}

export default router