import Layout from '@/layout'

const villageRouter = {
    path: '/fangyi',
    component: Layout,
    children: [{
        path: '',
        name: 'fangyi',
        meta: {
            title: "防疫排查"
        },
        component: () =>
            import ('@/views/fangyi/fangyi.vue')
    }, {
        path: '/fangyi/yimiao',
        name: 'yimiao',
        meta: {
            title: "微心愿"
        },
        component: () =>
            import ('@/views/fangyi/yimiao.vue')
    }, ]
}

export default villageRouter