/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

const router = {
    path: '/pension',
    component: Layout,
    children: [{
        path: '',
        name: 'pension',
        meta: {
            title: "智慧养老"
        },
        component: () =>
            import ('@/views/pension/index.vue')
    }, {
        path: 'healthy',
        name: 'healthy',
        meta: {
            title: "健康档案"
        },
        component: () =>
            import ('@/views/pension/healthy.vue')
    }, {
        path: 'shequ',
        name: 'shequ',
        meta: {
            title: "社区养老"
        },
        component: () =>
            import ('@/views/pension/shequ.vue')
    }, ]
}

export default router